.imx-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(14, 17, 24);
  opacity: 0.8;
  z-index: 1001;
}

.imx-sidebar {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 100vw;
  z-index: 1001;
  background-color: rgb(35, 43, 62);
  transition: transform 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  transform: translateX(100%);
}

.imx-sidebar-active {
  overflow-y: scroll;
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 375px;
  z-index: 1001;
  background-color: rgb(35, 43, 62);
  transition: transform 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  transform: translateX(0%);
}

.link-iframe {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh !important;
  width: 375px;
  z-index: 1001;
  background-color: rgb(35, 43, 62);
  transition: transform 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
}
