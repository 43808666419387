.steps {
  position: absolute;
  top: 32px;
  height: 2px;
}

.step0 {
  width: 0;
}

.step1 {
  width: calc(100% / 6);
}

.step2 {
  width: calc(100% / 2);
}

.step3 {
  width: calc(100% / 2 + 33%);
}

.step4 {
  width: calc(100%);
}

@media (min-width: 768px) {
  .step1 {
    width: calc(100% / 2 - 256px);
  }

  .step3 {
    width: calc(100% / 2 + 256px);
  }
}
